import { Button } from "antd";
import { Destination } from "./destinationSlice";

function ExportButton({ destinations }: { destinations: Destination[] }) {
  const startExport = () => {
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(destinations)], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "export_afnemers.json";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return <Button onClick={startExport}>Exporteer</Button>;
}

export default ExportButton;
