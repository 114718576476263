import { Button } from "antd";
import { useRef } from "react";
import { add, clearDestinations, Destination } from "./destinationSlice";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";

function ClearButton() {
  const inputFile = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const clear = () => {
    dispatch(clearDestinations());
  };
  return (
    <>
      <Button onClick={clear} type="primary" danger style={{ float: "right" }}>
        Alles verwijderen
      </Button>
    </>
  );
}

export default ClearButton;
