import { Button, Checkbox, Form, Input, InputNumber, Radio } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { add, Destination } from "./destinationSlice";
import { v4 as uuidv4 } from "uuid";

const DestinationForm: React.FC = () => {
  const dispatch = useDispatch();
  const onFinish = (values: any) => {
    dispatch(
      add({
        id: uuidv4(),
        name: values.name,
        city: `${values.city}, ${values.country}`,
      })
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      layout="horizontal"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Naam"
        name="name"
        rules={[{ required: true, message: "Voer een naam in!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Plaats"
        name="city"
        rules={[{ required: true, message: "Voer een plaats in!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Land" name="country">
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio.Button value="Nederland">Nederland</Radio.Button>
          <Radio.Button value="België">België</Radio.Button>
          <Radio.Button value="Duitsland">Duitsland</Radio.Button>
          <Radio.Button value="Luxemburg">Luxemburg</Radio.Button>
          <Radio.Button value="Frankrijk">Frankrijk</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
        <Button type="primary" htmlType="submit" block>
          Toevoegen
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DestinationForm;
